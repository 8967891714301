<template>
  <PageLayoutWrapper page="404">
    <div class="content-wrapper">
      <h1>404 – Page Not Found</h1>
    </div>
  </PageLayoutWrapper>
</template>

<script>
import PageLayoutWrapper from '@/components/PageLayoutWrapper'
import pageLayout from '@/mixins/pageLayoutMixin'

export default {
  components: { PageLayoutWrapper },
  mixins: [pageLayout],
}
</script>
